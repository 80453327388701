const AES = require('aes-js')

export default function(data, token, cipherIV) {
  const key = AES.utils.hex.toBytes(token)
  const paddedIV = cipherIV.padEnd(16, '0')
  const iv = AES.utils.utf8.toBytes(paddedIV).subarray(0, 16)
  const aesCbc = new AES.ModeOfOperation.cbc(key, iv)
  const dataBytes = AES.utils.utf8.toBytes(data)
  const paddedData = AES.padding.pkcs7.pad(dataBytes)
  const encryptedBytes = aesCbc.encrypt(paddedData)
  const encryptedHex = AES.utils.hex.fromBytes(encryptedBytes)
  return encryptedHex
}
