import { Request, Settings, Storage } from '.'

export default async function (name, headers = {}) {
  const host = Storage.get('settings').hostname
  const path = `${host}${Settings.apiBasePath}${name}`
  const { deviceId, deviceType } = Storage.get('deviceSettings')
  const requestHeaders = {
    deviceId,
    deviceType,
    ...headers
  }

  const login = Storage.get('login')
  if (login !== undefined) {
    requestHeaders.Authorization = `bearer ${login.token.access_token}`
  }

  const result = await Request(path, {
    headers: new Headers(requestHeaders)
  })
  return result
}
