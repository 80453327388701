import "es6-promise/auto";
import "url-search-params-polyfill";
import fetch from "fetch-ie8";
import env from "./env";

import("./const").then(({ PLATFORM_NAME }) => {
  import(`./platform/${PLATFORM_NAME}`).then(Platform => {
    if (!window.fetch) {
      window.fetch = fetch;
    }

    Platform.Load();
    env.Platform = Platform;
    // eslint-disable-next-line no-unused-expressions
    import("./boot");
  });
});
