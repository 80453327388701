import { Request, Settings, Storage } from '.'

export default async function (name, data = {}, headers = {}) {
  const host = Storage.get('settings').hostname
  const path = `${host}${Settings.apiBasePath}${name}`
  const { deviceId, deviceType } = Storage.get('deviceSettings')
  const requestHeaders = {
    'Content-Type': 'application/json',
    deviceId,
    deviceType,
    ...headers
  }

  const login = Storage.get('login')
  if (login !== undefined) {
    requestHeaders.Authorization = `bearer ${login.token.access_token}`
  }

  const result = await Request(path, {
    method: 'POST',
    body: JSON.stringify({ ...data, deviceType: Storage.get('deviceSettings').deviceType }),
    headers: new Headers(requestHeaders)
  })
  return result
}
