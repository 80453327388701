/* global webapis:true */
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import 'url-polyfill'
import { Storage } from '../../lib'

export default () => {
  var script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = '$WEBAPIS/webapis/webapis.js'
  script.onload = function() {
    if (!Storage.get('deviceSettings')) {
      Storage.set('deviceSettings', {
        deviceDescription: webapis.productinfo.getModel(),
        deviceId: webapis.productinfo.getDuid(),
        deviceType: 'smarttv',
        pucId: 'ac4d2fd61f624451a61aa2cf00a766a1'
      })
    }
  }
  document.head.appendChild(script)
}
